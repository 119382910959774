<template>
    <v-card
      color="transparent"
      elevation="0"
    >
      <v-card-title class="pl-0">
        {{ title }}
      </v-card-title>
      <v-card-text class="pl-0">
        {{ subtitle }}
      </v-card-text>
    </v-card>
</template>

<script>
export default {
  name: 'TitleWithSubtitle',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
  },
};
</script>
